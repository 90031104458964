import { FunctionComponent, ReactElement } from 'react';
import './loading-spinner.scss';

interface LoadingSpinnerProps {
	size?: string;
}

export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
	size = '3em',
}): ReactElement => (
	<div className="loading-spinner-wrapper" style={{ width: size, height: size }}>
		<div className="loading-spinner" style={{ width: size, height: size }} />
	</div>
);
