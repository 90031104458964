/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect, useRef } from 'react';

const useInViewport = (
	elementRef: React.RefObject<HTMLElement>,
	options?: IntersectionObserverInit,
	onViewportChange?: (isInViewport: boolean) => void
) => {
	const [isInViewport, setIsInViewport] = useState(false);
	const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

	const observerRef = useRef<IntersectionObserver | null>(null);

	useEffect(() => {
		const element = elementRef.current;

		if (!element) return;

		if (!observerRef.current) {
			observerRef.current = new IntersectionObserver(
				([entry]) => {
					const isVisible = entry.isIntersecting;
					setIsInViewport(isVisible);

					// Debounce the callback to avoid multiple rapid calls
					if (debounceTimeout.current) {
						clearTimeout(debounceTimeout.current);
					}

					debounceTimeout.current = setTimeout(() => {
						if (onViewportChange && isVisible) {
							onViewportChange(isVisible);
						}
					}, 200); // Adjust the debounce delay as needed
				},
				{ root: null, threshold: 0.5, ...options }
			);
		}

		observerRef.current.observe(element);

		return () => {
			observerRef.current?.disconnect();
			debounceTimeout.current && clearTimeout(debounceTimeout.current);
			observerRef.current = null;
		};
	}, [elementRef, options, onViewportChange]);

	return { isInViewport };
};

export default useInViewport;
