import { ColumnsAccessors } from './tasks.types';

export const ColumnNames: { [key in ColumnsAccessors]: string } = {
	[ColumnsAccessors.financialPlan]: 'Financial Plan',
	[ColumnsAccessors.relevant]: 'Relevant',
	[ColumnsAccessors.discussed]: 'Discussed',
	[ColumnsAccessors.status]: 'Status',
	[ColumnsAccessors.notes]: 'Notes',
	[ColumnsAccessors.assignedTask]: 'Assigned Task',
	[ColumnsAccessors.assignee]: 'Assignee',
	[ColumnsAccessors.taskNotes]: 'Task Notes',
	[ColumnsAccessors.dueDate]: 'Due Date',
};

export const dateFormat = 'YYYY-MM-DD';

export const yesNoOptions = [
	{
		label: 'Yes',
		value: 'true',
	},
	{
		label: 'No',
		value: 'false',
	},
];

export const statusOptions = [
	{
		label: 'Not Started',
		value: 'not_started',
	},
	{
		label: 'In Progress',
		value: 'in_progress',
	},
	{
		label: 'Completed',
		value: 'completed',
	},
];
