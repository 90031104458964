import { useState, ChangeEvent, FunctionComponent } from 'react';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '../tasks.types';

const NextBestActionsTextField: FunctionComponent<TextFieldProps> = ({
	initialValue,
	item,
	accessor = '',
	onChange,
}) => {
	const [value, setValue] = useState(initialValue);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = event.target.value;
		setValue(newValue);
		onChange(event, item);
	};

	return (
		<TextField
			multiline
			size="small"
			variant="outlined"
			sx={{ width: '20rem' }}
			id={`${item.id}-${accessor}`}
			value={value}
			onChange={handleChange}
		/>
	);
};

export default NextBestActionsTextField;
