/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flowKeys } from '@/app/queries/query-keys/flows';
import { useFlashMessage } from '@/app/flash-messages';
import {
	updateNextBestActionsAiOutput,
	UpdateAiOutputParams,
	updateNextBestActionsTasks,
	UpdateNextBestActionsTasksParams,
} from '@/app/api/flows';
import { queryKeys } from '@/app/queries';
import { AssigneeData, AssigneeQueryParams } from '@/app/api/flows/next-best-actions/tasks.types';
import { PaginatedCustom } from '@/app/api/api.types';
import { ADVISOR_OFFSET, CLIENT_OFFSET } from './next-best-actions.constants';

export const useNextBestActionsAiOutput = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.nextBestActionsAiOutput(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useUpdateNextBestActionsAiOutput = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateAiOutputParams) => {
			return updateNextBestActionsAiOutput({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.nextBestActionsAiOutput._def,
			});
		},
	});
};

export const useNextBestActionsTasks = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.nextBestActionsTasks(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useUpdateNextBestActionsTasks = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateNextBestActionsTasksParams) => {
			return updateNextBestActionsTasks({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.nextBestActionsTasks._def,
			});
		},
	});
};

export const useNextBestActionsTasksAssignees = ({
	page,
	size,
	name,
	advisorOffset,
	clientOffset,
}: AssigneeQueryParams) => {
	const [assigneesData, setAssigneesData] = useState<PaginatedCustom<AssigneeData>>();

	const query = useQuery({
		...flowKeys
			.nextBestActionsTasksAssignees({ page, size, advisorOffset, clientOffset })
			._ctx.search({ name }),
		placeholderData: keepPreviousData,
	});

	const { data: assignees } = query;

	useEffect(() => {
		if (name === '')
			setAssigneesData({
				content: [],
				advisorOffset: ADVISOR_OFFSET,
				clientOffset: CLIENT_OFFSET,
				pageSize: 0,
				lastPage: false,
			});
	}, [name]);

	useEffect(() => {
		if (assignees && !name) {
			const { content } = assignees;
			setAssigneesData({
				...assignees,
				content: [...(assigneesData ? [...assigneesData.content] : []), ...content],
			});
			return;
		}
		setAssigneesData(assignees);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assignees]);

	return { ...query, data: assigneesData };
};
