import { FunctionComponent, ReactElement } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Table } from '@/components/ui/table';
import tasksColumns, { ColumnsProps } from './columns/tasks-columns';
import { TasksTableData } from '@/app/api/flows/next-best-actions/tasks.types';
import './tasks-table.scss';

interface TasksTableProps extends ColumnsProps {
	action: boolean;
	onSubmit: () => void;
}

const TasksTable: FunctionComponent<TasksTableProps> = ({
	table,
	action,
	assignees,
	isFetching,
	isLastPage,
	onSelectChange,
	onInputChange,
	onDateChange,
	onAssigneeChange,
	onAssigneeSearch,
	onAssigneeLoadMore,
	onSubmit,
}): ReactElement => {
	const { data: rows = [] } = table || {};

	const columns = tasksColumns({
		onSelectChange,
		onInputChange,
		onDateChange,
		onAssigneeChange,
		onAssigneeSearch,
		onAssigneeLoadMore,
		table,
		assignees,
		isFetching,
		isLastPage,
	});

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{action && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							variant="contained"
							size="small"
							color="primary"
							sx={{ marginTop: '1rem' }}
							onClick={onSubmit}
						>
							Save
						</Button>
					</Box>
				)}
				<Box sx={{ display: 'flex' }}>
					<Typography component="h3" variant="h6" sx={{ fontWeight: '500', marginY: '2rem' }}>
						{table?.tableLabel}
					</Typography>
				</Box>
			</Box>
			<Table<TasksTableData>
				className="tasks-table"
				columns={columns}
				rows={rows}
				ariaLabel={`${table?.tableLabel} table`}
			/>
		</>
	);
};

export default TasksTable;
