/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, ReactNode } from 'react';
import NextBestActionsAutocomplete from '../../../components/tasks-autocomplete';
import {
	AutocompleteParams,
	AutocompleteParamsSearch,
	OnChangeParams,
	SelectProps,
} from '@/client/next-best-actions/tasks/tasks.types';
import { AssigneeData } from '@/app/api/flows/next-best-actions/tasks.types';

interface AutocompleteCellProps {
	options: AssigneeData[];
	item: SelectProps['item'];
	accessor: keyof SelectProps['item'];
	placeholder?: string;
	isFetching?: boolean;
	isLastPage?: boolean;
	onChange: ({ event, item, accessor }: AutocompleteParams) => void;
	onAssigneeSearch: ({ event, item, accessor }: AutocompleteParamsSearch) => void;
	onLoadMore?: ({ item, accessor }: OnChangeParams) => void;
}

const AutocompleteCell: FunctionComponent<AutocompleteCellProps> = ({
	options,
	accessor = 'assignee',
	item,
	placeholder,
	isFetching,
	isLastPage,
	onChange,
	onAssigneeSearch,
	onLoadMore,
}): ReactNode => (
	<NextBestActionsAutocomplete
		item={item}
		value={item[accessor] as AssigneeData}
		data={options}
		accessor={accessor}
		placeholder={placeholder}
		isLastPage={isLastPage}
		isFetching={isFetching}
		onSelect={(event) => onChange({ event, item, accessor })}
		onInputChange={(event) => onAssigneeSearch({ event, item, accessor })}
		onLoadMore={() => {
			if (onLoadMore) onLoadMore({ item, accessor });
		}}
	/>
);

export default AutocompleteCell;
