/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FunctionComponent, ReactElement } from 'react';
import { AutocompleteProps } from '../tasks.types';
import Autocomplete from '@/components/ui/inputs/autocomplete';
import { AutocompleteItem } from '@/components/ui/inputs/autocomplete/autocomplete.types';

const NextBestActionsAutocomplete: FunctionComponent<AutocompleteProps> = ({
	data,
	placeholder,
	value,
	item,
	accessor = '',
	isFetching,
	isLastPage,
	onSelect,
	onInputChange,
	onLoadMore,
}): ReactElement => {
	const options = data.map((d) => ({ ...d, group: d.type, label: d.name, id: d.id }));
	const val = { label: value?.name, group: value?.type, id: value?.id };

	return (
		<Autocomplete
			id={`${accessor}-${item.id}-id`}
			data={options}
			value={val}
			isLastPage={isLastPage}
			isFetching={isFetching}
			placeholder={placeholder}
			onSelect={(val: AutocompleteItem) => onSelect(val)}
			onInputChange={onInputChange}
			onLoadMore={onLoadMore}
		/>
	);
};

export default NextBestActionsAutocomplete;
