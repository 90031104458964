/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FunctionComponent, ReactElement } from 'react';
import { Select, MenuItem } from '@mui/material';
import { SelectProps, YesNoValue } from '../tasks.types';

const NextBestActionsSelect: FunctionComponent<SelectProps> = ({
	value,
	item,
	options,
	accessor = '',
	handleChange,
}): ReactElement => {
	return (
		<Select
			size="small"
			displayEmpty
			autoWidth
			id={`${accessor}-${item.id}-id`}
			name={`${accessor}-${item.id}-name`}
			labelId={`${item.id}-${accessor}-label`}
			value={value}
			onChange={(event) => handleChange(event, item)}
		>
			<MenuItem value={-1} disabled>
				Choose Option
			</MenuItem>
			{options.map((n: YesNoValue) => (
				<MenuItem key={`${n.label}-label`} value={n.value as any}>
					{n.label}
				</MenuItem>
			))}
		</Select>
	);
};

export default NextBestActionsSelect;
