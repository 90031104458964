/* eslint-disable @typescript-eslint/no-unused-vars */
import { FunctionComponent, useRef } from 'react';
import useInViewport from '@/shared/hooks/useInViewport';

export interface LoaderProps {
	onLoadMore: () => void;
}

const LoadMore: FunctionComponent<LoaderProps> = ({ onLoadMore }) => {
	const elementRef = useRef<HTMLDivElement | null>(null);

	useInViewport(
		elementRef,
		{
			rootMargin: '0px',
			threshold: 0.9,
		},
		onLoadMore
	);

	return <div style={{ height: '10px' }} ref={elementRef} />;
};

export default LoadMore;
