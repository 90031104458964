/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, ReactNode } from 'react';
import { InputParams, SelectProps } from '@/client/next-best-actions/tasks/tasks.types';
import NextBestActionsTextField from '@/client/next-best-actions/tasks/components/tasks-text-field';

interface FieldCellProps {
	item: SelectProps['item'];
	accessor: keyof SelectProps['item'];
	onChange: ({ event, item, accessor }: InputParams) => void;
}

const FieldCell: FunctionComponent<FieldCellProps> = ({ accessor, item, onChange }): ReactNode => (
	<NextBestActionsTextField
		item={item}
		initialValue={item[accessor] as string}
		onChange={(event) => onChange({ event, item, accessor })}
		accessor={accessor}
	/>
);

export default FieldCell;
