import { PaginationQueryParams, SearchQueryParams } from '../api.types';

export type ClientsQueryParams = PaginationQueryParams & SearchQueryParams;

export interface ClientQueryParams {
	clientId: string | number | undefined;
}

export enum ClientStatus {
	NOT_STARTED = 'NOT_STARTED',
	COMPLETED = 'COMPLETED',
	FAST_BRAIN = 'FAST_BRAIN',
	SLOW_BRAIN = 'SLOW_BRAIN',
	RISK_ASSESSMENT = 'RISK_ASSESSMENT',
	PORTFOLIO_RECOMMENDATIONS = 'PORTFOLIO_RECOMMENDATIONS',
	PORTFOLIO_CONSIDERATIONS = 'PORTFOLIO_CONSIDERATIONS',
	LIFE_PLAN = 'LIFE_PLAN',
	NEXT_BEST_ACTIONS = 'NEXT_BEST_ACTIONS',
	RTQ = 'RTQ',
}

export enum ClientStatusLabel {
	NOT_STARTED = 'Not Started',
	COMPLETED = 'Completed',
	FAST_BRAIN = 'Fast Brain',
	SLOW_BRAIN = 'Slow Brain',
	RISK_ASSESSMENT = 'Risk Assessment',
	PORTFOLIO_RECOMMENDATIONS = 'Portfolio Recommendations',
	PORTFOLIO_CONSIDERATIONS = 'Portfolio Considerations',
	LIFE_PLAN = 'Life Plan',
	NEXT_BEST_ACTIONS = 'Next Best Actions',
	RTQ = 'RTQ',
}

export enum ClientTypes {
	NOT_SET = 'NOT_SET',
	HOUSEHOLD = 'HOUSEHOLD',
	INDIVIDUAL = 'INDIVIDUAL',
}

export type ClientType = ClientTypes.NOT_SET | ClientTypes.HOUSEHOLD | ClientTypes.INDIVIDUAL;

export enum TaxTypes {
	NOT_SET = 'NOT_SET',
	NO_TAX = 'NO_TAX',
	TAX = 'TAX',
}

export type TaxType = TaxTypes.NOT_SET | TaxTypes.NO_TAX | TaxTypes.TAX;

export interface Client {
	id: number;
	erpId: string;
	clientName: string;
	clientEmail: string;
	clientUrl: string;
	ownerId: string;
	status: ClientStatus;
	statusLabel: ClientStatusLabel;
	clientType: ClientType;
	taxType: TaxType;
}

interface ClientAdditionalSettings {
	auth: boolean;
	password: string;
}

interface ClientSettingsFlows {
	flowId: number;
	flowName: string;
	flowOrder: number;
}

export interface ClientSettings {
	clientId: number;
	flows: ClientSettingsFlows[];
	additionalSettings: ClientAdditionalSettings;
	initialSetup: boolean;
}

export interface ClientPayloadData {
	clientId: number;
	flows: string[] | undefined;
	auth?: boolean;
	password?: string | null;
}

interface UpdateClientSettingsRequest extends ClientPayloadData {}

export interface UpdateClientSettingsParams {
	clientId: string | number;
	payload: UpdateClientSettingsRequest;
}

export type Flow = 'fast_brain' | 'slow_brain' | 'risk_assessment';

export interface ClientActivityLog {
	clientId: number;
	flow: Flow;
	startTime: string;
	endTime: string;
	label: string;
}
