/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import type { Dayjs } from 'dayjs';
import { SelectChangeEvent } from '@mui/material';
import { AssigneeData, TasksTableData } from '@/app/api/flows/next-best-actions/tasks.types';

export enum ColumnsAccessors {
	financialPlan = 'financialPlan',
	relevant = 'relevant',
	discussed = 'discussed',
	status = 'status',
	notes = 'notes',
	assignedTask = 'assignedTask',
	assignee = 'assignee',
	taskNotes = 'taskNotes',
	dueDate = 'dueDate',
}

export interface YesNoValue {
	label: string;
	value: string;
}

export interface SelectProps {
	value: string | number | boolean | AssigneeData | null;
	item: TasksTableData;
	options: YesNoValue[];
	handleChange: (
		event: SelectChangeEvent<boolean | number | string | AssigneeData | null>,
		item: TasksTableData
	) => void;
	accessor?: keyof SelectProps['item'];
}

export interface TextFieldProps {
	initialValue: string;
	item: TasksTableData;
	onChange: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		item: TasksTableData
	) => void;
	accessor?: keyof SelectProps['item'];
}

export interface AutocompleteProps {
	value: AssigneeData;
	item: TasksTableData;
	data: AssigneeData[];
	placeholder?: string;
	isFetching?: boolean;
	isLastPage?: boolean;
	onInputChange: (val: string) => void;
	onSelect: (val: any) => void;
	onLoadMore?: () => void;
	accessor?: keyof SelectProps['item'];
}

export interface OnChangeParams {
	item: TasksTableData;
	accessor: keyof SelectProps['item'];
	table?: string | undefined;
}

export interface SelectParams extends OnChangeParams {
	event: SelectChangeEvent<number | string | boolean | AssigneeData | null>;
}

export interface InputParams extends OnChangeParams {
	event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}

export interface DateParams extends OnChangeParams {
	event: Dayjs | null;
}

export interface AutocompleteParams extends OnChangeParams {
	event: AssigneeData | null;
}

export interface AutocompleteParamsSearch extends OnChangeParams {
	event: string;
}
