import { AuthState } from '@okta/okta-auth-js';

export const AUTH_KEY = 'authInfo';

export const toKebabCase = (str: string): string => {
	return str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase();
};

export const copyLink = (text: string) => {
	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	navigator.clipboard.writeText(text);
};

export const getAuthInfo = () => JSON.parse(localStorage.getItem(AUTH_KEY) || '{}') as AuthState;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => void>(
	func: T,
	delay: number = 300
): (...args: Parameters<T>) => void {
	let timeoutId: ReturnType<typeof setTimeout>;

	return (...args: Parameters<T>): void => {
		// Clear the previous timeout
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timeout
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
}
