import { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { QuestionAnswer } from '@/app/api/flows';
import { TextItem } from '@/components/layouts/text-item';
import { AccordionList } from '@/components/ui/accordion';
import CompletedFor from '../../components/completed-for';
import { DialogueContext } from '../../dialogues.types';
import { useRiskAssessmentQuestionsAnswers } from '../risk-assessment.queries';
import {
	GENERAL_INVESTMENT_QUESTIONS,
	INVESTMENT_BEHAVIOR_QUESTIONS,
	EMOTIONAL_RISK_TOLERANCE,
} from '../risk-assessment.constants';
import { MAPPED_TAX_TYPES } from '@/clients/clients.constants';

const RiskAssessmentTileSelectionPage: FunctionComponent = (): ReactElement | null => {
	const { client } = useOutletContext<DialogueContext>();

	const { data: tileQuestionsAnswers } = useRiskAssessmentQuestionsAnswers(client.id);
	const { questions = [] } = tileQuestionsAnswers || {};
	const [{ questionAndAnswers = [] } = {}] = questions;

	const generalInvestment = useMemo(
		() => questionAndAnswers.filter((qa) => qa.category === GENERAL_INVESTMENT_QUESTIONS),
		[questionAndAnswers]
	);
	const investmentBehavior = useMemo(
		() => questionAndAnswers.filter((qa) => qa.category === INVESTMENT_BEHAVIOR_QUESTIONS),
		[questionAndAnswers]
	);
	const emotionalRiskTolerance = useMemo(
		() => questionAndAnswers.filter((qa) => qa.category === EMOTIONAL_RISK_TOLERANCE),
		[questionAndAnswers]
	);

	const accordionChildren = (questionsAndAnswers: QuestionAnswer[]): ReactNode => {
		const items: ReactNode[] = questionsAndAnswers.map(({ question, answer }) => (
			<TextItem title={question} key={question}>
				<Typography variant="body1">{answer}</Typography>
			</TextItem>
		));

		return (
			<Stack direction="column" gap="0.5rem">
				{items}
			</Stack>
		);
	};

	const generalInvestmentAccordion = useMemo(
		() => ({
			id: 'general-investment-questions',
			title: GENERAL_INVESTMENT_QUESTIONS,
			defaultExpanded: true,
			children: accordionChildren(generalInvestment),
		}),
		[generalInvestment]
	);
	const investmentBehaviorAccordion = useMemo(
		() => ({
			id: 'investment-behavior-questions',
			title: INVESTMENT_BEHAVIOR_QUESTIONS,
			defaultExpanded: false,
			children: accordionChildren(investmentBehavior),
		}),
		[investmentBehavior]
	);
	const emotionalRiskToleranceAccordion = useMemo(
		() => ({
			id: 'emotional-risk-tolerance',
			title: EMOTIONAL_RISK_TOLERANCE,
			defaultExpanded: false,
			children: accordionChildren(emotionalRiskTolerance),
		}),
		[emotionalRiskTolerance]
	);

	return (
		<>
			<CompletedFor completedFor={MAPPED_TAX_TYPES[client.taxType]} />
			<AccordionList
				items={[
					generalInvestmentAccordion,
					investmentBehaviorAccordion,
					emotionalRiskToleranceAccordion,
				]}
			/>
		</>
	);
};

export default RiskAssessmentTileSelectionPage;
