import { FunctionComponent, ReactElement, useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import TasksTable from './tasks-table';
import { AssigneeData, Tasks, TasksTableData } from '@/app/api/flows/next-best-actions/tasks.types';
import { Client } from '@/app/api/clients';
import {
	useNextBestActionsTasks,
	useNextBestActionsTasksAssignees,
	useUpdateNextBestActionsTasks,
} from '../next-best-actions.queries';
import {
	AutocompleteParams,
	AutocompleteParamsSearch,
	ColumnsAccessors,
	DateParams,
	InputParams,
	SelectParams,
	SelectProps,
} from './tasks.types';
import { debounce } from '@/shared/utils';
import { dateFormat } from './tasks.constants';
import { ADVISOR_OFFSET, CLIENT_OFFSET } from '../next-best-actions.constants';
import { PaginationCustomQueryParams } from '@/app/api/api.types';

interface SetTableProps {
	value: number | string | boolean | Partial<AssigneeData> | null;
	item: TasksTableData;
	accessor: keyof SelectProps['item'];
	table: string | undefined;
}

const NextBestActionsTasksPage: FunctionComponent = (): ReactElement => {
	const [tables, setTables] = useState<Tasks[]>([]);
	const [search, setSearch] = useState<string>();
	const [offsets, setOffsets] = useState<PaginationCustomQueryParams>({
		advisorOffset: ADVISOR_OFFSET,
		clientOffset: CLIENT_OFFSET,
	});

	const client = useOutletContext<Client>();
	const { id } = client;
	const { data } = useNextBestActionsTasks(id);

	const { data: assignees, isFetching } = useNextBestActionsTasksAssignees({
		name: search,
		...offsets,
	});

	useEffect(() => {
		if (data) {
			setTables(data);
		}
	}, [data]);

	const { mutate: updateNextBestActionsTasks } = useUpdateNextBestActionsTasks();

	const onSubmit = () => {
		if (id) {
			updateNextBestActionsTasks({ clientId: id, payload: tables });
		}
	};

	const setTableValues = ({ value, item, accessor, table }: SetTableProps) => {
		const newTbl = tables.find((tb) => tb.table === table);
		const newTblData = newTbl?.data;
		const idx = newTblData?.findIndex((it) => it.id === item.id) as number;

		if (newTblData && idx >= 0) {
			newTblData[idx] = { ...newTblData[idx], [accessor]: value };
			setTables([...tables]);
		}
	};

	const handleTextChange = ({ event, item, accessor, table }: InputParams) => {
		const { value } = event.target;
		setTableValues({ value, item, accessor, table });
	};

	const debouncedOnChange = debounce(handleTextChange);

	const handleSelectChange = ({ event, item, accessor, table }: SelectParams) => {
		let value;
		if (accessor === ColumnsAccessors.status) value = event.target.value;
		else value = event.target.value === 'true';
		setTableValues({ value, item, accessor, table });
	};

	const handleDateChange = ({ event, item, accessor, table }: DateParams) => {
		const value = dayjs(event).format(dateFormat);
		setTableValues({ value, item, accessor, table });
	};

	const onAssigneeSearch = ({ event }: AutocompleteParamsSearch) => {
		if (event !== search) {
			setSearch(event);
			setOffsets({
				advisorOffset: ADVISOR_OFFSET,
				clientOffset: CLIENT_OFFSET,
			});
		}
	};

	const debouncedOnAssigneeSearch = debounce(onAssigneeSearch, 400);

	const onAssigneeChange = ({ event, item, accessor, table }: AutocompleteParams) => {
		const value = event?.id ? { id: event?.id, type: event?.type, name: event?.name } : null;
		setTableValues({ value, item, accessor, table });
	};

	const onAssigneeLoadMore = () => {
		const { advisorOffset, clientOffset } = assignees || {};
		setOffsets({
			advisorOffset: advisorOffset ?? ADVISOR_OFFSET,
			clientOffset: clientOffset ?? CLIENT_OFFSET,
		});
	};

	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!tables) return <></>;

	return (
		<>
			{tables.map((table: Tasks, index: number) => (
				<TasksTable
					onSelectChange={handleSelectChange}
					onInputChange={debouncedOnChange}
					onDateChange={handleDateChange}
					onAssigneeSearch={debouncedOnAssigneeSearch}
					onAssigneeChange={onAssigneeChange}
					onAssigneeLoadMore={onAssigneeLoadMore}
					onSubmit={onSubmit}
					assignees={assignees?.content || []}
					isFetching={isFetching}
					isLastPage={assignees?.lastPage || false}
					action={!index}
					table={table}
					key={table.table}
				/>
			))}
		</>
	);
};

export default NextBestActionsTasksPage;
