/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Column } from '@/components/ui/table';
import SelectCell from '../cells/select-cell';
import FieldCell from '../cells/input-cell';
import DateCell from '../cells/date-cell';
import { AssigneeData, Tasks, TasksTableData } from '@/app/api/flows/next-best-actions/tasks.types';
import {
	AutocompleteParams,
	AutocompleteParamsSearch,
	ColumnsAccessors,
	DateParams,
	InputParams,
	OnChangeParams,
	SelectParams,
} from '@/client/next-best-actions/tasks/tasks.types';
import {
	ColumnNames,
	statusOptions,
	yesNoOptions,
} from '@/client/next-best-actions/tasks/tasks.constants';
import AutocompleteCell from '../cells/autocomplete-cell';

export interface ColumnsProps {
	table?: Tasks;
	assignees: AssigneeData[];
	isFetching: boolean;
	isLastPage: boolean;
	onSelectChange: ({ event, item, accessor, table }: SelectParams) => void;
	onInputChange: ({ event, item, accessor, table }: InputParams) => void;
	onDateChange: ({ event, item, accessor, table }: DateParams) => void;
	onAssigneeChange: ({ event, item, accessor, table }: AutocompleteParams) => void;
	onAssigneeSearch: ({ event, item, accessor, table }: AutocompleteParamsSearch) => void;
	onAssigneeLoadMore?: ({ item, accessor, table }: OnChangeParams) => void;
}

const tasksColumns = ({
	onSelectChange,
	onInputChange,
	onDateChange,
	onAssigneeChange,
	onAssigneeSearch,
	onAssigneeLoadMore,
	table,
	assignees,
	isFetching,
	isLastPage,
}: ColumnsProps) => {
	const columns: Column<TasksTableData>[] = [
		{ name: ColumnNames.financialPlan, accessors: [ColumnsAccessors.financialPlan] },
		{
			name: ColumnNames.relevant,
			accessors: [ColumnsAccessors.relevant],
			render: (item) => (
				<SelectCell
					options={yesNoOptions}
					item={item}
					accessor={ColumnsAccessors.relevant}
					onChange={({ event, item, accessor }) =>
						onSelectChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
		{
			name: ColumnNames.discussed,
			accessors: [ColumnsAccessors.discussed],
			render: (item) => (
				<SelectCell
					options={yesNoOptions}
					item={item}
					accessor={ColumnsAccessors.discussed}
					onChange={({ event, item, accessor }) =>
						onSelectChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
		{
			name: ColumnNames.status,
			accessors: [ColumnsAccessors.status],
			render: (item) => (
				<SelectCell
					options={statusOptions}
					item={item}
					accessor={ColumnsAccessors.status}
					onChange={({ event, item, accessor }) =>
						onSelectChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
		{
			name: ColumnNames.notes,
			accessors: [ColumnsAccessors.notes],
			render: (item) => (
				<FieldCell
					item={item}
					accessor={ColumnsAccessors.notes}
					onChange={({ event, item, accessor }) =>
						onInputChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
		{
			name: ColumnNames.assignedTask,
			accessors: [ColumnsAccessors.assignedTask],
			render: (item) => (
				<SelectCell
					options={yesNoOptions}
					item={item}
					accessor={ColumnsAccessors.assignedTask}
					onChange={({ event, item, accessor }) =>
						onSelectChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
		{
			name: ColumnNames.assignee,
			accessors: [ColumnsAccessors.assignee],
			render: (item) => (
				<AutocompleteCell
					options={assignees}
					item={item}
					accessor={ColumnsAccessors.assignee}
					placeholder={ColumnNames.assignee}
					isFetching={isFetching}
					isLastPage={isLastPage}
					onChange={({ event, item, accessor }) =>
						onAssigneeChange({ event, item, accessor, table: table?.table })
					}
					onAssigneeSearch={({ event, item, accessor }) =>
						onAssigneeSearch({ event, item, accessor, table: table?.table })
					}
					onLoadMore={({ item, accessor }) => {
						if (onAssigneeLoadMore) onAssigneeLoadMore({ item, accessor, table: table?.table });
					}}
				/>
			),
		},
		{
			name: ColumnNames.taskNotes,
			accessors: [ColumnsAccessors.taskNotes],
			render: (item) => (
				<FieldCell
					item={item}
					accessor={ColumnsAccessors.taskNotes}
					onChange={({ event, item, accessor }) =>
						onInputChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
		{
			name: ColumnNames.dueDate,
			align: 'right',
			accessors: [ColumnsAccessors.dueDate],
			render: (item) => (
				<DateCell
					item={item}
					accessor={ColumnsAccessors.dueDate}
					onChange={({ event, item, accessor }) =>
						onDateChange({ event, item, accessor, table: table?.table })
					}
				/>
			),
		},
	];

	return columns;
};

export default tasksColumns;
