/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { AutocompleteItem as AutocompleteItemPropsData } from '../autocomplete.types';
import LoadMore from '@/shared/components/load-more';
import LoadingSpinner from '@/components/ui/loading-spinner';
import '../autocomplete.scss';

interface AutocompleteItemProps {
	options: AutocompleteItemPropsData[];
	option: AutocompleteItemPropsData;
	props: React.HTMLAttributes<HTMLLIElement> & {
		key: any;
	};
	ownerState: any;
	isFetching?: boolean;
	isLastPage?: boolean;
	onLoadMore?: () => void;
}

const AutocompleteItem: React.FC<AutocompleteItemProps> = ({
	props,
	ownerState,
	option,
	options,
	isFetching,
	isLastPage,
	onLoadMore,
}): React.ReactElement => {
	const isLastItem = useMemo(() => option === options[options.length - 1], [option, options]);
	const { key, ...optionProps } = props;
	const hasLoadMore = !isFetching && !isLastPage;

	return (
		<li key={`${key}-${option.id}`}>
			<Box component="span" {...optionProps}>
				{ownerState?.getOptionLabel(option) ?? ''}
			</Box>
			{isLastItem ? (
				<div className="load-more-wrapper">
					{isFetching ? <LoadingSpinner size="1em" /> : <></>}
					{onLoadMore && hasLoadMore && <LoadMore onLoadMore={onLoadMore} />}
				</div>
			) : (
				<></>
			)}
		</li>
	);
};

export default AutocompleteItem;
