import { FunctionComponent } from 'react';
import { Box, Divider, ListItem, Typography } from '@mui/material';

interface CompletedForProps {
	completedFor: string;
}

const CompletedFor: FunctionComponent<CompletedForProps> = ({ completedFor }) => {
	return (
		<Box sx={{ marginBottom: '2rem' }}>
			<ListItem disablePadding>
				<Typography variant="h6" py="0.75rem">
					Completed for: {completedFor}
				</Typography>
			</ListItem>
			<Divider />
		</Box>
	);
};

export default CompletedFor;
