import { httpClient } from '../../http-client';
import { PaginatedCustom } from '../../api.types';
import { AssigneeData, AssigneeQueryParams, Tasks } from './tasks.types';

export const getNextBestActionsTasks = async (clientId: string | number): Promise<Tasks[]> => {
	const { data } = await httpClient.get<Tasks[]>(`/next-best-actions/${clientId}`);

	return data;
};

export const updateNextBestActionsTasks = async ({
	clientId,
	payload,
}: {
	clientId: string | number | undefined;
	payload: Tasks[];
}): Promise<Tasks[]> => {
	const { data } = await httpClient.put<Tasks[]>(`/next-best-actions/${clientId}`, payload);

	return data;
};

export const getAssignees = async (
	params: AssigneeQueryParams
): Promise<PaginatedCustom<AssigneeData>> => {
	const { data } = await httpClient.get<PaginatedCustom<AssigneeData>>('clients/assignees', {
		params,
	});

	return data;
};
