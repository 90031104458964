/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, ReactNode } from 'react';
import NextBestActionsSelect from '@/client/next-best-actions/tasks/components/tasks-select';
import {
	SelectParams,
	SelectProps,
	YesNoValue,
} from '@/client/next-best-actions/tasks/tasks.types';

interface SelectCellProps {
	options: YesNoValue[];
	item: SelectProps['item'];
	accessor: keyof SelectProps['item'];
	onChange: ({ event, item, accessor }: SelectParams) => void;
}

const SelectCell: FunctionComponent<SelectCellProps> = ({
	options,
	accessor,
	item,
	onChange,
}): ReactNode => (
	<NextBestActionsSelect
		item={item}
		value={item[accessor]}
		options={options}
		accessor={accessor}
		handleChange={(event) => onChange({ event, item, accessor })}
	/>
);

export default SelectCell;
