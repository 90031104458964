import { ClientTypes, TaxTypes } from '@/app/api/clients/clients.types';

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 10;

export const MAPPED_CLIENT_TYPES = {
	[ClientTypes.NOT_SET]: 'Not set',
	[ClientTypes.HOUSEHOLD]: 'Household',
	[ClientTypes.INDIVIDUAL]: 'Individual',
};

export const MAPPED_TAX_TYPES = {
	[TaxTypes.NOT_SET]: 'Not set',
	[TaxTypes.NO_TAX]: 'No tax',
	[TaxTypes.TAX]: 'Tax',
};
