import { AutocompleteRenderGroupParams, styled } from '@mui/material';

interface AutocompleteGroupProps {
	params: AutocompleteRenderGroupParams;
}

const AutocompleteGroup: React.FC<AutocompleteGroupProps> = ({ params }): React.ReactElement => {
	const GroupHeader = styled('div')(() => ({
		position: 'sticky',
		padding: '4px 10px',
		color: '#6e6e6e',
		backgroundColor: '#ececec',
		fontWeight: '500',
		textTransform: 'capitalize',
	}));

	const GroupItems = styled('ul')({
		padding: 0,
	});

	return (
		<li key={params.key}>
			<GroupHeader>{params.group}</GroupHeader>
			<GroupItems>{params.children}</GroupItems>
		</li>
	);
};

export default AutocompleteGroup;
