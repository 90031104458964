import { FunctionComponent, ReactNode } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { DateParams, SelectProps } from '@/client/next-best-actions/tasks/tasks.types';

interface DateCellProps {
	item: SelectProps['item'];
	accessor: keyof SelectProps['item'];
	onChange: ({ event, item, accessor }: DateParams) => void;
}

const DateCell: FunctionComponent<DateCellProps> = ({ accessor, item, onChange }): ReactNode => (
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<DatePicker
			value={dayjs(item.dueDate)}
			onChange={(event: Dayjs | null) => onChange({ event, item, accessor })}
			sx={{
				cursor: 'pointer',
				width: '10rem',
				border: 'none',
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						border: 'none',
					},
				},
			}}
		/>
	</LocalizationProvider>
);

export default DateCell;
